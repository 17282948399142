/**
 * Capitalized all first letter of every word in a string
 *
 * @param str <string>
 * @return <string>
 */
export function ucwords (str) {
  if (!str) return '';

  if (!(typeof str === 'string')) return '';

  return (`${str.toLowerCase()}`).
    replace(/^(.)|\s+(.)/g, (char) => {
      return char.toUpperCase();
    });
}

/**
 * Check if the given string is likely JSON or a regular string.
 * To distinguish between a regular string and a JSON string, you can leverage the fact that a JSON string typically starts with { or [.
 * If a string starts with either of these characters, it's likely JSON; otherwise, it's a regular string.
 *
 * @param str {string}
 * @returns {boolean}
 */
export function isJsonString(str) {
  // Trim leading and trailing whitespace
  str = str.trim();

  // Check if the string starts with '{' (object) or '[' (array)
  return str.startsWith('{') || str.startsWith('[');
}
